import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import iconSvg from "./include/images/icon_.svg";
import icon1 from "./include/images/close-red-icon.svg";
import ReactHtmlParser from "react-html-parser";
class ErrorPopup extends Component {
  state = {};
  render() {
    return (
      <Modal
        className={`modal fade custom-modal`}
        dialogClassName="modal-dialog modal-dialog-centered modal-sm"
        show={this.props.error}
        onHide={() => this.props.setError(false)}
      >
        <div className="cm-content-box position-relative">
          <div className="cm-header-row w-100 d-flex align-items-center">
            <div className="cm-heading d-flex flex-wrap align-items-center">
              <div className="cm-icon">
                <img src={iconSvg} alt="" />
              </div>
              <div className="cm-text">{ReactHtmlParser(this.props.title)}</div>
            </div>
          </div>

          <div className="connect-with-wallet-box text-center">
            <div className="cwwb-icon">
              <img src={icon1} alt="" />
            </div>
            <h5>{this.props.error}</h5>
            <h5>{this.props.error1}</h5>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ErrorPopup;
