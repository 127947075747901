import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

import iconSvg from "./include/images/icon_.svg";
import wallet1 from "./include/images/wallet-1.png";
import wallet2 from "./include/images/wallet-2.png";
import wallet3 from "./include/images/wallet-3.png";

import { useWeb3React } from "@web3-react/core";
import { connectors } from "./web3/connectors";
import ErrorPopup from "./errorPopup";
import LoaderPopup from "./loaderPopup";
import { isMobile } from "react-device-detect";
import LogRocket from "logrocket";

const ConnectPopup = (props) => {
  const [errors, setErrors] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [signMessage, setSignMessage] = React.useState(true);
  const [signature, setSignature] = React.useState("");
  const { activate, library, chainId, account, active, deactivate, error } =
    useWeb3React();
  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };

  useEffect(() => {
    if (account) {
      async function getSignature() {
        if (!signMessage) await signMessages();
      }
      getSignature();
    }
  }, [active]);

  const signMessages = async () => {
    if (!library) return;
    try {
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [
          `I want to login on Burn Ghost at ${new Date()}. I accept the Burn Ghost Terms of Service ${
            window.location.protocol
          }//${window.location.host}/terms and I am at least 13 years old.`,
          account,
        ],
      });
      setSignature(signature);
      setLoading(false);
      props.changeTab(account);
    } catch (error) {
      setLoading(false);
      setErrors(error.message);
      deactivate();
      localStorage.clear();
    }
  };

  useEffect(() => {
    if (error) {
      setLoading(false);
      setErrors(
        error.message === "No Ethereum provider was found on window.ethereum."
          ? "Please install metamask extension first. "
          : error.message
      );
      if (error.code === 403) window.location.href = "/connect-error";

      props.togglePopup();
      localStorage.removeItem("provider");
    }
  }, [error]);

  const activating = (params) => {
    try {
      setLoading(true);
      activate(params);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <LoaderPopup connecting={loading} type={props.type} />
      <ErrorPopup
        error={errors}
        setError={setErrors}
        title={`<div className="cm-text">CONNECT TO <span>BURN GHOST</span></div>`}
      />
      <Modal
        className={`modal fade custom-modal $`}
        id={"connectModal"}
        dialogClassName="modal-dialog modal-dialog-centered modal-sm"
        tabindex="-1"
        show={props.showPopup}
        onHide={() => props.togglePopup()}
      >
        <div className="cm-content-box position-relative">
          <div className="cm-header-row w-100 d-flex align-items-center">
            <div className="cm-heading d-flex flex-wrap align-items-center">
              <div className="cm-icon">
                <img src={iconSvg} alt="" />
              </div>
              <div className="cm-text">
                CONNECT TO <span>BURN GHOST</span>
              </div>
            </div>
          </div>
          <div className="cm-title-row">
            <h1>CONNECT WALLET</h1>
            <h3>CHOOSE HOW YOU WANT TO CONNECT</h3>
          </div>
          <div className="cm-form-row">
            {!isMobile && (
              <a
                href="#!"
                className="fm-form-column d-flex flex-wrap align-items-center"
                onClick={(e) => {
                  e.preventDefault();
                  setSignMessage(false);
                  activating(connectors.injected);
                  setProvider("injected");
                  props.togglePopup();
                }}
              >
                <div className="fm-form-icon">
                  <img src={wallet1} alt="" />
                </div>
                <div className="fm-form-text">
                  <h5>METAMASK</h5>
                </div>
              </a>
            )}
            <a
              href="#!"
              className="fm-form-column d-flex flex-wrap align-items-center"
              onClick={(e) => {
                e.preventDefault();
                setSignMessage(false);
                activating(connectors.coinbaseWallet);
                setProvider("coinbaseWallet");
                props.togglePopup();
              }}
            >
              <div className="fm-form-icon">
                <img src={wallet2} alt="" />
              </div>
              <div className="fm-form-text">
                <h5>COINBASE WALLET</h5>
              </div>
            </a>
            <a
              href="#!"
              className="fm-form-column d-flex flex-wrap align-items-center"
              onClick={(e) => {
                e.preventDefault();
                setSignMessage(false);
                activating(connectors.walletConnect);
                setProvider("walletConnect");
                props.togglePopup();
              }}
            >
              <div className="fm-form-icon">
                <img src={wallet3} alt="" />
              </div>
              <div className="fm-form-text">
                <h5>WALLET CONNECT</h5>
              </div>
            </a>
          </div>
          <div className="cm-pagination-row"></div>
        </div>
      </Modal>
    </>
  );
};

export default ConnectPopup;
