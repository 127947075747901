import axios from "axios";

export const subscribeWaitingList = async (data) => {
  try {
    const headers = {
      "X-API-KEY": process.env.REACT_APP_APIKEY,
    };
    const response = await axios({
      method: "post",
      headers,
      url: `${process.env.REACT_APP_APIURL}userWaitList`,
      data,
    });
    return response;
  } catch (e) {
    throw e;
  }
};
