import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import iconSvg from "./include/images/meta-logo.svg";
import iconSvg1 from "./include/images/icon_.svg";

class LoaderPopup extends Component {
  state = {};
  render() {
    return (
      <Modal
        className={`modal fade custom-modal `}
        dialogClassName="modal-dialog modal-dialog-centered modal-sm"
        show={this.props.connecting}
        onHide={() => this.props.setError(false)}
      >
        <div
          className="cm-content-box position-relative"
          style={{ padding: "30px 30px 45px" }}
        >
          <div className="cm-header-row w-100 d-flex align-items-center">
            <div className="cm-heading d-flex flex-wrap align-items-center">
              <div className="cm-icon">
                <img src={iconSvg1} alt="" />
              </div>
              <div className="cm-text">
                CONNECT TO <span>BURN GHOST</span>
              </div>
            </div>
          </div>

          <div className="connect-with-wallet-box text-center">
            <div class="cwwb-icon add-large-timer">
              <svg
                class="circle-icon animate"
                width="91"
                height="91"
                viewBox="0 0 91 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="5.875"
                  y="5.65625"
                  width="80.125"
                  height="80.125"
                  rx="40.0625"
                  stroke="#E5601E"
                  stroke-width="10"
                  stroke-dasharray="252"
                  stroke-dashoffset="180"
                />
                <image
                  width="30"
                  height="30"
                  xlinkHref={iconSvg}
                  x="30"
                  y="30"
                  clip-path="url(#myCircle)"
                />
              </svg>
            </div>
            <h5>COMMUNICATING WITH WALLET</h5>
            <h6>SIGN MESSAGE WITH YOUR WALLET</h6>
          </div>
        </div>
      </Modal>
    );
  }
}

export default LoaderPopup;
