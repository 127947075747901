import React, { Component, useState } from "react";
import { Modal } from "react-bootstrap";
import LoaderImg from "./loaderImag";
import ConnectWallet from "./connectWallet";
import { subscribeWaitingList } from "./apis";

import logoImage from "./include/images/logo_1.png";
import check_icon_1 from "./include/images/check__icon__1.svg";

const JoinWaitlist = () => {
  const [modal, setModal] = useState(1);
  const [email, setEmail] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [emailError, setEmailError] = useState(false);
  return (
    <React.Fragment>
      <div
        className={` mian-wrapper-container dark-bg
    `}
      ></div>
      <Modal
        className={`modal fade custom-modal text-center`}
        dialogClassName="modal-dialog modal-dialog-centered modal-sm"
        show={modal === 1}
        onHide={() => {}}
        id={"joinTheWaitlist"}
        tabindex="-1"
      >
        <div class={`custom-modal-box `}>
          <div class="logo__area">
            <img src={logoImage} alt="" />
          </div>
          <h3 class="text-uppercase">JOIN THE WAITLIST</h3>
          <div class="testi-text-box">
            <p>“Who the hell is Burn Ghost?</p>
            <p>“Join the waitlist for what?</p>
            <p>“When are you going live?</p>
          </div>
          <h6>Jeez you ask a lot of questions.</h6>
          <div class="para-group-box">
            <p>You’re curious. We like that.</p>
            <p>We need players like you.</p>
          </div>
          <div class="custom-modal-action-btn">
            <a
              href="#!"
              class="btn btn-default btn-block"
              onClick={(e) => {
                e.preventDefault();
                setModal(2);
              }}
            >
              Connect Wallet
            </a>
          </div>
        </div>
      </Modal>{" "}
      <Modal
        className={`modal fade custom-modal text-center`}
        dialogClassName="modal-dialog modal-dialog-centered modal-sm"
        show={modal === 3}
        onHide={() => {}}
        id={"listModal"}
        tabindex="-1"
      >
        <div class="custom-modal-box">
          <div class="logo__area">
            <img src={logoImage} alt="" />
          </div>
          <h3 class="text-uppercase text-center jwl-title m-0">
            {!confirmed
              ? "BE THE FIRST TO KNOW, SIGN UP FOR UPDATES."
              : "YOU’RE ON THE LIST."}
          </h3>
          {!confirmed ? (
            <div class="mt-form-group text-start">
              <label class="form-label">EMAIL ADDRESS</label>
              <div class="input-group-row d-flex align-items-center">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <button
                  type="button"
                  class={`cnf-btn ${loading && "loading btn-block disabled"} ${
                    !email && "disabled"
                  }`}
                  onClick={async (e) => {
                    e.preventDefault();
                    if (email) {
                      const data = { email, walletAddress };
                      setLoading(true);
                      subscribeWaitingList(data)
                        .then((res) => {
                          setLoading(false);
                          setConfirmed(true);
                          setEmailError(false);
                        })
                        .catch((err) => {
                          setEmailError(true);
                          setLoading(false);
                        });
                    }
                  }}
                >
                  {loading ? <LoaderImg /> : "SUBMIT"}
                </button>
              </div>
              {emailError && (
                <label class="form-label-error">Invalid Email Id!</label>
              )}
            </div>
          ) : (
            <div class="tick-icon-box position-relative mt-5">
              <span>
                You're on the list.{" "}
                <a href="https://burnghost.com/">Go to Homepage</a>
              </span>
              <img class="check__icon__1" src={check_icon_1} alt="" />
            </div>
          )}{" "}
          <div class="mt-or-row overflow-hidden">
            <span>and</span>
          </div>
          <a
            href="https://discord.com/invite/burnghost"
            target="_blank"
            class="mt-twitter-button rounded-pill d-flex align-items-center justify-content-center"
          >
            <div class="mt-twitter-box position-relative">
              <i class="fab fa-discord"></i> <span>JOIN OUR COMMUNITY</span>
            </div>
          </a>
        </div>
      </Modal>
      <ConnectWallet
        togglePopup={(e) => setModal(1)}
        showPopup={modal === 2}
        loginLoading={loginLoading}
        setLoginLoading={setLoginLoading}
        type="waitlist"
        changeTab={(val) => {
          setWalletAddress(val);
          setModal(3);
        }}
      />
    </React.Fragment>
  );
};

export default JoinWaitlist;
